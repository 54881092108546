import React, { useState, useEffect, useContext } from "react";
import {
  Card,
  Input,
  List,
  Empty,
  Skeleton,
  Breadcrumb,
  Button,
  Select,
  Tag,
} from "antd";
import { FaSearch } from "react-icons/fa";
import { Header } from "../components";
import { fetchProducts } from "../utils/fetchProducts";
import { ThreeDot } from "react-loading-indicators";
import { useNavigate, Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

import AuthContext from "../contexts/AuthProvider";

import {
  HomeOutlined,
  UserOutlined,
  ProductOutlined,
  SyncOutlined,
} from "@ant-design/icons";

const { Search } = Input;

const Products = () => {
  const { auth } = useContext(AuthContext);

  const [refreshPage, setRefreshPage] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [originalData, setOriginalData] = useState([]);
  const [dataError, setDataError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState("prices");
  const baseUrl = `${window.location.protocol}//${window.location.host}`;

  const navigate = useNavigate();

  useEffect(() => {
    setIsDataLoading(true);
    setDataError(null);
    console.log("auth", auth);
    const fetchData = async () => {
      try {
        const vals = await fetchProducts();
        setOriginalData(vals);
        console.log(vals.map((item) => item.name).sort());
        if (searchParams.get("searchText")) {
          setData(
            vals.filter((item) =>
              (item.name + item.matching_string)
                .toLowerCase()
                .includes(
                  searchParams
                    .get("searchText")
                    .toLowerCase()
                    .replace(/[\s-]/g, "")
                )
            )
          );
          setSearchText(searchParams.get("searchText"));
        } else {
          setData(vals);
        }
        setIsDataLoading(false);
      } catch (error) {
        setData(null);
        setDataError(error);
        setIsDataLoading(false);
      }
    };
    fetchData();
  }, [refreshPage]);

  const handleUpdateSearchText = (value) => {
    console.log("value to search: ", value);
    setSearchText(value);

    const filteredData = originalData.filter((item) =>
      (item.matching_string + item.name)
        .toLowerCase()
        .includes(value.toLowerCase().replace(/[\s-]/g, ""))
    );

    setData(filteredData);
    setSearchParams({ searchText: value }); // Update the URL with the searched text
  };

  // const handleRemoveProducts = async (products) => {
  //   const removeProductsID = products.map((item) => item.product_id);
  //   try {
  //     await removeProducts(removeProductsID);
  //     setData(
  //       data.filter((item) => !removeProductsID.includes(item.product_id))
  //     );
  //   } catch (error) {
  //     console.error("Failed to remove products:", error);
  //   }
  // };

  const handleDetailChange = (e) => {
    setSelectedDetail(e.target.value);
  };

  return (
    <>
      <div className="">
        {/* <div className="mb-5 flex justify-between">
          <Header category="Página" title="Productos" />
        </div> */}
        {/* <Breadcrumb
          items={[
            {
              href: "/home",
              title: <HomeOutlined />,
            },
            {
              href: "",
              title: (
                <>
                  <ProductOutlined />
                  <span>Productos</span>
                </>
              ),
            },
          ]}
          className="mb-5"
        /> */}
        <div className=" bg-white rounded-lg">
          <div
            className="w-full flex justify-between"
            style={{ zIndex: 10000 }}
          >
            <div className="flex space-x-2">
              <Button
                icon={<SyncOutlined />}
                onClick={() => setRefreshPage(!refreshPage)}
              ></Button>
              {/* <Select
                mode="tags"
                className="min-w-56"
                style={{ width: "100%" }}
                onChange={(value) => console.log("newvalue in select", value)}
                tokenSeparators={[","]}
                options={[
                  {
                    value: true,
                    label: "Con Stock",
                  },
                  {
                    value: false,
                    label: "Sin Stock",
                  },
                ]}
                defaultValue={[true, false]}
              /> */}
            </div>
            <Search
              placeholder="Buscar productos"
              onSearch={handleUpdateSearchText}
              // enterButton
              style={{ width: 550 }}
              allowClear
              value={searchText}
              onChange={(event) => {
                console.log("val", event.target.value);
                setSearchText(event.target.value);
              }}
            />
            <div className=" ">
              <Button
                className="mr-2"
                onClick={() => {
                  window.location.href = baseUrl + "/products/create";
                }}
                type="dashed"
              >
                Añadir
              </Button>
            </div>
          </div>
          {isDataLoading ? (
            <div className="w-full text-center min-h-72 flex items-center py-6">
              <div
                className="flex flex-col w-full space-y-3"
                // style={{
                //   gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                //   gap: "15px",
                // }}
              >
                <Card className="w-full">
                  <Skeleton active paragraph={{ rows: 3 }} />
                </Card>
                <Card className="w-full">
                  <Skeleton active paragraph={{ rows: 3 }} />
                </Card>
                <Card className="w-full">
                  <Skeleton active paragraph={{ rows: 3 }} className="" />
                </Card>
                <Card className="w-full">
                  <Skeleton active paragraph={{ rows: 3 }} className="" />
                </Card>
              </div>
            </div>
          ) : dataError ? (
            <div>No se pudo cargar los productos.</div>
          ) : data.length === 0 ? (
            <div className="w-full flex-columns ">
              <Empty />
            </div>
          ) : (
            <div
              className="overflow-y-auto py-6"
              // style={{ maxHeight: "calc(100vh - 270px)" }}
            >
              <div
                className="flex flex-col space-y-3"

                // style={{
                //   display: "grid",
                //   gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                //   gap: "15px",
                // }}
              >
                {data.map((product) => (
                  <Link
                    to={`/products/${product.product_id}`}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <Card
                      key={product.product_id}
                      // hoverable
                      title={<div className="text-sm">{product.name}</div>}
                      className=""
                    >
                      <div className="w-full flex">
                        <div className="w-full">
                          <p>
                            <strong>Matching String:</strong>{" "}
                            {product.matching_string}
                          </p>
                          <p>
                            <strong>Precio único:</strong>{" "}
                            {!product.has_offer ? "Sí" : "No"}
                          </p>
                          <div>
                            <div className="font-bold">Stock Variantes</div>
                            <List size="small" className="mx-3">
                              {product.variants_array?.map((variant) => (
                                <List.Item>
                                  <span className="text-sm">
                                    {variant.variant} -{" "}
                                    {variant.stock ? (
                                      <Tag color="green">Stock</Tag>
                                    ) : (
                                      <Tag color="red">Sin Stock</Tag>
                                    )}
                                  </span>
                                </List.Item>
                              ))}
                            </List>
                          </div>
                        </div>

                        <div>
                          {product.image && (
                            <img
                              src={product.image}
                              alt="Uploaded"
                              className="rounded-lg max-w-32 max-h-32"
                            />
                          )}
                        </div>
                      </div>

                      {/* <p>
                        <strong>Precios:</strong>
                      </p> */}
                      {/* <List
                        dataSource={product.prices_array
                          .sort((a, b) => a.quantity - b.quantity)
                          .slice(0, 3)}
                        renderItem={(item) => (
                          <List.Item>
                            {item.quantity} x ${item.price}
                          </List.Item>
                        )}
                      /> */}
                      {/* {product.prices_array.length > 3 && <p>...</p>} */}
                    </Card>
                  </Link>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Products;
