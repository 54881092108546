import React, { useState, useEffect } from "react";
import imageCompression from "browser-image-compression";
import { Input, Button, Switch, message, InputNumber, Upload } from "antd";
import {
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import {
  editHasOffer,
  editProductGeneralInformation,
} from "../../utils/fetchProducts";

import api from "../../api/axios";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

const compressImage = async (file) => {
  const options = {
    maxSizeMB: 0.05, // 0.05MB = 50KB
    maxWidthOrHeight: 1920, // Adjust resolution if needed
    useWebWorker: true, // Enable web worker for better performance
  };

  try {
    const compressedFile = await imageCompression(file, options);
    return compressedFile;
  } catch (error) {
    console.error("Image compression failed:", error);
    throw new Error("Compression failed");
  }
};

function getBase64ImageSize(base64String) {
  // Remove the data URL part if it's there
  const base64 = base64String.split(",")[1] || base64String;

  // Calculate the padding count (usually 0, 1, or 2)
  const padding = (base64.match(/=+$/) || []).length;

  // Calculate the base64 string length
  const base64Length = base64.length;

  // Calculate the size in bytes
  const sizeInBytes = (base64Length * 3) / 4 - padding;

  // Convert size from bytes to kilobytes (optional)
  const sizeInKB = sizeInBytes / 1024;

  return {
    bytes: sizeInBytes,
    kilobytes: sizeInKB,
  };
}

const GeneralInformation = ({ data, onSave }) => {
  const [base64Image, setBase64Image] = useState("");
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState({
    name: false,
    matching_string: false,
  });
  const [inputValues, setInputValues] = useState({});

  const handleUpload = async (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/webp";

    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/WEBP files!");
      return false;
    }

    let imageFile = file;

    // If image is larger than 50KB, compress it
    if (file.size / 1024 > 50) {
      try {
        imageFile = await compressImage(file);
        message.success("Image compressed successfully!");
      } catch (error) {
        message.error("Image compression failed.");
        return false;
      }
    }

    // Convert to base64
    const base64 = await getBase64(imageFile);
    const newData = { ...inputValues, image: base64 };
    delete newData.has_offer;

    console.log("this is newData", newData);
    console.log("this is the base64 image size", getBase64ImageSize(base64));

    setLoading(true);
    try {
      const val = await editProductGeneralInformation(newData);
      setBase64Image(base64);
      message.success("Image updated successfully!");
    } catch (error) {
      message.error("Error updating image. Please refresh the page.");
      console.error("error", error);
    } finally {
      setLoading(false);
    }

    return false; // Prevent default upload behavior
  };

  // const removeImage = () => {};

  useEffect(() => {
    console.log("this is the initial General Information data", data);
    setInputValues({
      product_id: data.product_id,
      name: data.name || "",
      matching_string: data.matching_string || "",
      image: data.image || "",
      has_offer: data.has_offer || false,
      quantity: data.quantity,
      weight: data.weight || 0,
      includes_gift: data.includes_gift || false,
    });
    setBase64Image(data.image);
  }, [data]);

  const handleEdit = (field) => {
    setIsEditing({ ...isEditing, [field]: true });
  };

  const handleSave = async (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    const newData = { ...inputValues };
    console.log("esto es new data", newData);
    delete newData.has_offer;
    try {
      const val = editProductGeneralInformation(newData);
      message.success(`Información actualizada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const handleToggleChange = async (checked) => {
    setInputValues({ ...inputValues, has_offer: checked });
    onSave({ ...data, has_offer: checked });
    try {
      const val = editHasOffer(data.product_id, checked);
      message.success(`Información actualizada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
    } finally {
    }
  };

  const handleCancel = (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    setInputValues({ ...inputValues, [field]: data[field] });
  };

  const handleChange = (field, value) => {
    const newData = { ...inputValues, [field]: value };
    console.log("handleChange data", newData);
    setInputValues(newData);
  };

  const removeImage = () => {
    const newData = { ...inputValues, image: "" };
    delete newData.has_offer;
    console.log("removeImage newData", newData);
    try {
      const val = editProductGeneralInformation(newData);
      message.success(`Imagen eliminada correctamente!`);
    } catch (error) {
      message.error(`Error al actualizar la información.`);
      console.log("error", error);
    } finally {
      // setLoading(false);
    }
  };

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
      className="h-48 w-48"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div>Subir Imagen</div>
    </button>
  );

  return (
    <>
      <div className="flex w-full">
        <div className="w-full ">
          <div className="mb-4">
            <label className="block text-gray-700">Nombre Producto</label>
            {!isEditing.name ? (
              <div className="flex items-center">
                <span>{inputValues.name}</span>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit("name")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <Input
                  value={inputValues.name}
                  onChange={(e) => handleChange("name", e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSave("name");
                    }
                  }}
                />
                <Button
                  type="text"
                  icon={<SaveOutlined />}
                  onClick={() => handleSave("name")}
                  style={{ marginLeft: 8 }}
                />
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => handleCancel("name")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Matching String</label>
            {!isEditing.matching_string ? (
              <div className="flex items-center">
                <span>{inputValues.matching_string}</span>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit("matching_string")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <Input
                  value={inputValues.matching_string}
                  onChange={(e) =>
                    handleChange("matching_string", e.target.value)
                  }
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSave("matching_string");
                    }
                  }}
                />
                <Button
                  type="text"
                  icon={<SaveOutlined />}
                  onClick={() => handleSave("matching_string")}
                  style={{ marginLeft: 8 }}
                />
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => handleCancel("matching_string")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">
              Descuento por cantidad
            </label>
            <Switch
              checked={inputValues.has_offer}
              onChange={handleToggleChange}
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700">Incluye Regalo</label>
            <Switch
              checked={inputValues.includes_gift}
              onChange={(value) => {
                handleChange("includes_gift", value);
                handleSave("includes_gift");
              }}
            />
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Peso Artículo</label>
            {!isEditing.weight ? (
              <div className="flex items-center">
                <span>{inputValues.weight}</span>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit("weight")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <InputNumber
                  value={inputValues.weight}
                  onChange={(value) => handleChange("weight", value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSave("weight");
                    }
                  }}
                />
                <Button
                  type="text"
                  icon={<SaveOutlined />}
                  onClick={() => handleSave("weight")}
                  style={{ marginLeft: 8 }}
                />
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => handleCancel("weight")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-gray-700">Divisor</label>
            {!isEditing.quantity ? (
              <div className="flex items-center">
                <span>{inputValues.quantity}</span>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  onClick={() => handleEdit("quantity")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            ) : (
              <div className="flex items-center">
                <InputNumber
                  value={inputValues.quantity}
                  onChange={(value) => handleChange("quantity", value)}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleSave("quantity");
                    }
                  }}
                />
                <Button
                  type="text"
                  icon={<SaveOutlined />}
                  onClick={() => handleSave("quantity")}
                  style={{ marginLeft: 8 }}
                />
                <Button
                  type="text"
                  icon={<CloseOutlined />}
                  onClick={() => handleCancel("quantity")}
                  style={{ marginLeft: 8 }}
                />
              </div>
            )}
          </div>

          {/* {!inputValues.has_offer && (
          <div className="mb-4">
            <label className="block text-gray-700">Price</label>
            <InputNumber
              value={inputValues.single_price}
              onChange={(value) => handleChange("single_price", value)}
              onBlur={() => handleSave("single_price")}
              style={{ width: "100%" }}
            />
          </div>
        )} */}
        </div>
        <div
          className={`w-48 h-48 rounded-lg text-center flex items-center transition-all duration-100 ease-in-out ${
            base64Image
              ? " hover:bg-gray-50 hover:outline-1 hover:outline-dashed hover:outline-slate-400"
              : "bg-gray-50 outline-1 outline-dashed outline-slate-400"
          }`}
        >
          <Upload
            beforeUpload={handleUpload}
            // listType="picture-card"
            className="w-48 h-48 flex items-center justify-center"
            showUploadList={false} // Hide the default upload list
          >
            {base64Image ? (
              <div className="text-center flex items-end flex-col">
                <img
                  src={base64Image}
                  alt="Uploaded"
                  className="rounded-lg max-w-48 max-h-48"
                />
              </div>
            ) : (
              uploadButton
            )}
          </Upload>
        </div>
        {base64Image && (
          <div className="h-full ml-2">
            <Button
              danger
              type="text"
              className="w-8 h-8"
              onClick={() => {
                removeImage();
                setBase64Image(undefined);
              }}
            >
              <CloseOutlined />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default GeneralInformation;
