import React, { useState, useEffect } from "react";

import { Header, DataTable } from "../components";
import { Button } from "antd";
import { ThreeDot } from "react-loading-indicators";
import { fetchOrders, removeOrders } from "../utils/fetchOrders";
import { fetchDrivers } from "../utils/fetchDrivers";
import { fromDatetoYearMonthDay } from "../utils/utils";

import { useParams, useNavigate } from "react-router-dom";

import {
  generateRoutePDF,
  generateExcelAllDrivers,
  generateLoadDriverPDF,
} from "../utils/generatePDFs";

const DashboardDailyRoute = () => {
  const navigate = useNavigate();
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const params = useParams();
  const routeDay = new Date(params.routeDay);

  const [data, setData] = useState([]);
  const [drivers, setDrivers] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true); // change it to false when data fetched
  const [dataError, setDataError] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);

  useEffect(async () => {
    setIsDataLoading(true);
    setDataError(null);
    // generateLoadDriverPDF(orders, driver, date);
    const fetchData = async () => {
      try {
        const vals = await fetchOrders(null, fromDatetoYearMonthDay(routeDay));
        setData(vals);
        const drivers = await fetchDrivers();
        setDrivers(drivers);
        setIsDataLoading(false);
      } catch (error) {
        console.log(error);
        setData(null);
        setDataError(error);
        setIsDataLoading(false);
      }
    };
    fetchData();
  }, [refreshPage]);

  const columns = [
    { header: "ID", accessorKey: "daily_id", footer: "ID" },
    { header: "Nombre", accessorKey: "name", footer: "Name" },
    { header: "Dirección", accessorKey: "address", footer: "Adress" },
    { header: "Comuna", accessorKey: "city", footer: "City" },
    {
      header: "Productos",
      accessorKey: "products",
      footer: "Products",
      cell: ({ row }) =>
        row.original.products !== undefined &&
        row.original.products
          .filter((item) => item.product_name !== "NotFound")
          .map((item) => (
            <>
              {item.product_name} <br />
            </>
          )),
    },
  ];

  const handleRemoveOrders = async (orders) => {
    const removeOrdersID = orders.map((item) => item.order_id);
    try {
      removeOrders(removeOrdersID).then((value) => {
        setRefreshPage(!refreshPage);
      });
    } catch (error) {
      throw Error(error.message);
    }
  };

  const handleDownloadAllRoutes = () => {
    drivers.forEach((driver) => {
      if (
        data.filter(
          (item) =>
            item.delivery_info_array[item.delivery_info_array.length - 1]
              .driver_id === driver.personnel_id
        ).length > 0
      ) {
        generateRoutePDF(
          // This should be according to the route day
          data.filter(
            (item) =>
              item.delivery_info_array[item.delivery_info_array.length - 1]
                .driver_id === driver.personnel_id
          ),
          driver.name,
          fromDatetoYearMonthDay(routeDay)
        );
      }
    });
  };

  const handleDownloadAllLoads = () => {
    drivers.forEach((driver) => {
      if (
        data.filter(
          (item) =>
            item.delivery_info_array[item.delivery_info_array.length - 1]
              .driver_id === driver.personnel_id
        ).length > 0
      ) {
        generateLoadDriverPDF(
          data.filter(
            (item) =>
              item.delivery_info_array[item.delivery_info_array.length - 1]
                .driver_id === driver.personnel_id
          ),
          driver.name,
          fromDatetoYearMonthDay(routeDay)
        );
      }
    });
  };

  return (
    <>
      <div className="m-10 mt-5 p-10 pt-6 bg-white rounded-3xl">
        {/* Showing data saved correctly */}
        <div className="mb-5 flex justify-between">
          <Header category="Aplicación" title="Rutas y Cargas" />
        </div>
        <div className="">
          {isDataLoading ? (
            <div className="w-full text-center min-h-72 flex items-center justify-center">
              <ThreeDot
                variant="brick-stack"
                color="#4048F1"
                size="medium"
                text=""
                textColor=""
              />
            </div>
          ) : dataError ? (
            <div>Error al cargar pedidos.</div>
          ) : (
            <>
              <div></div>
              <div>
                {drivers.map((driver) => {
                  if (driver.name !== "No asignado") {
                    return (
                      <div className="flex px-5 py-2 items-center">
                        <div className="min-w-24">{driver.name}</div>{" "}
                        <div className="min-w-40 px-3 text-center">
                          {data.filter(
                            (item) =>
                              item.delivery_info_array[
                                item.delivery_info_array.length - 1
                              ].driver_id === driver.personnel_id
                          ).length > 0 ? (
                            <Button
                              onClick={() => {
                                generateRoutePDF(
                                  // This should be according to the route day
                                  data.filter(
                                    (item) =>
                                      item.delivery_info_array[
                                        item.delivery_info_array.length - 1
                                      ].driver_id === driver.personnel_id
                                  ),
                                  driver.name,
                                  fromDatetoYearMonthDay(routeDay)
                                );
                              }}
                            >
                              Descargar Ruta
                            </Button>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                        <div className="min-w-40 px-3">
                          {data.filter(
                            (item) =>
                              item.delivery_info_array[
                                item.delivery_info_array.length - 1
                              ].driver_id === driver.personnel_id
                          ).length > 0 ? (
                            <Button
                              onClick={() => {
                                generateLoadDriverPDF(
                                  data.filter(
                                    (item) =>
                                      item.delivery_info_array[
                                        item.delivery_info_array.length - 1
                                      ].driver_id === driver.personnel_id
                                  ),
                                  driver.name,
                                  fromDatetoYearMonthDay(routeDay)
                                );
                              }}
                            >
                              Descargar Carga
                            </Button>
                          ) : (
                            <div>-</div>
                          )}
                        </div>
                      </div>
                    );
                  }
                })}
                <Button onClick={() => generateExcelAllDrivers(data, drivers)}>
                  Descargar Excel
                </Button>
                <Button onClick={handleDownloadAllRoutes}>
                  Descargar Rutas
                </Button>
                <Button onClick={handleDownloadAllLoads}>
                  Descargar Cargas
                </Button>
              </div>

              <DataTable
                addButton={false}
                columns={columns}
                data={data}
                columnRowClick={"order_id"}
                callbackRefresh={() => {
                  setRefreshPage(!refreshPage);
                }}
                callbackRemove={(removeElements) =>
                  handleRemoveOrders(removeElements)
                }
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardDailyRoute;
