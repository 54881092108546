import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  message,
  List,
  Popover,
  Typography,
  Switch,
} from "antd";

import {
  PlusOutlined,
  SaveOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseOutlined,
} from "@ant-design/icons";

import {
  removePricesProducts,
  addPricesProducts,
  fetchProductPricesById,
} from "../../utils/fetchProducts";

import { groupPrices } from "../../utils/utils";

import { getBaseUnitPrice } from "../../utils/utils";
import { ListItem } from "@mui/material";

const { Title } = Typography;

const PricesProducts = ({ productID }) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const [prices, setPrices] = useState(null);
  const [isPricesLoading, setisPricesLoading] = useState(true);
  const [loadingAddButton, setLoadingAddButton] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [newPrice, setNewPrice] = useState({ quantity: "", price: "" });
  const [isEditingPriceRange, setIsEditingPriceRange] = useState([]);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const [newUnitPrice, setNewUnitPrice] = useState(false);

  const isEditing = (record) => record.key === editingKey;

  const handleDelete = async (id) => {
    const newData = prices.filter((item) => item.id !== id);
    try {
      const result = await removePricesProducts([id]);
      setPrices(newData);
      message.success("Precio eliminado correctamente!");
    } catch (error) {
      message.error("Error al actualizar el precio");
    }
  };

  const handleAdd = async () => {
    if (newPrice.quantity && newPrice.price) {
      const newData = [...prices, { key: Date.now(), ...newPrice }];
      setLoadingAddButton(true);
      try {
        const result = await addPricesProducts(
          productID,
          newPrice.quantity,
          newPrice.price
        );
        setPrices(
          newData.sort((a, b) => Number(a.quantity) - Number(b.quantity))
        );
        setNewPrice({ quantity: "", price: "" });
        setLoadingAddButton(false);
        message.success("Precio añadido correctamente!");
      } catch (error) {
        message.error("Error al agregar el precio.");
        setLoadingAddButton(false);
      }
    } else {
      message.warning("Debes indicar una cantidad y un precio.");
    }
  };

  const handleSave = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...prices];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setPrices(newData);
        setEditingKey("");
        message.success("Price saved successfully!");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
      message.error("Failed to save price.");
    }
  };

  const handleBulkAction = () => {
    // Perform your bulk action here with the selectedRowKeys
    message.success("Bulk action performed on selected prices.");
  };

  const handleSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleAdd();
    }
  };

  const [groupedPrices, setGroupedPrices] = useState(null);

  useEffect(async () => {
    setisPricesLoading(true);
    try {
      const prices = await fetchProductPricesById(Number(productID));
      const sortedPricesByQuantity = prices.sort(
        (a, b) => a.quantity - b.quantity
      );
      const groupedPrices = groupPrices(sortedPricesByQuantity);
      setPrices(prices);
      setGroupedPrices(groupedPrices);
      setIsEditingPriceRange(groupedPrices.map((item) => false));
    } catch (error) {
      message.error("Error al obtener los precios");
      console.log("error", error);
    } finally {
      setisPricesLoading(false);
    }
  }, []);

  // useEffect(() => {
  //   prices.map((item) =>
  //     console.log(
  //       "quantity: " +
  //         item.quantity +
  //         " - price: " +
  //         item.price +
  //         " - unit Price: " +
  //         getBaseUnitPrice(item.quantity, item.price)
  //     )
  //   );
  // }, []);

  const columns = [
    {
      title: "Cantidad",
      dataIndex: "quantity",
      width: "30%",
      editable: true,
    },
    {
      title: "Precio",
      dataIndex: "price",
      width: "30%",
      editable: true,
    },
    {
      title: "Acción",
      width: "10%",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              onClick={() => handleSave(record.key)}
              icon={<SaveOutlined />}
            />
            <Button
              type="link"
              onClick={() => setEditingKey("")}
              icon={<CloseOutlined />}
            />
          </span>
        ) : (
          <span>
            <Popconfirm
              title={
                <div>
                  <div>Esta acción no se puede deshacer.</div>
                  <div>¿Confirma eliminación?</div>
                </div>
              }
              onConfirm={() => handleDelete(record.id)}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  // const mergedColumns = columns.map((col) => {
  //   if (!col.editable) {
  //     return col;
  //   }
  //   return {
  //     ...col,
  //     onCell: (record) => ({
  //       record,
  //       inputType: col.dataIndex === "price" ? "number" : "text",
  //       dataIndex: col.dataIndex,
  //       title: col.title,
  //       editing: isEditing(record),
  //     }),
  //   };
  // });

  return (
    <>
      <div className="flex justify-center mb-4 w-full">
        <div className="w-2/3 flex justify-between">
          <InputNumber
            placeholder="Quantity"
            value={newPrice.quantity}
            onChange={(value) => setNewPrice({ ...newPrice, quantity: value })}
            onKeyPress={handleKeyPress}
          />
          <InputNumber
            placeholder="Price"
            value={newPrice.price}
            onChange={(value) => setNewPrice({ ...newPrice, price: value })}
            onKeyPress={handleKeyPress}
          />
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={handleAdd}
            loading={loadingAddButton}
          >
            Agregar Precio
          </Button>
        </div>
      </div>
      <div className="w-full flex justify-center">
        <div className="w-2/3">
          <Table
            // rowSelection={{
            //   selectedRowKeys,
            //   onChange: handleSelectChange,
            // }}
            // components={{
            //   body: {
            //     cell: EditableCell,
            //   },
            // }}
            // bordered
            dataSource={prices}
            columns={columns}
            // rowClassName="editable-row"
            pagination={false}
          />
        </div>
      </div>
      <div className="p-3 ">
        <Switch
          checked={showAdvancedOptions}
          onChange={(value) => {
            setShowAdvancedOptions(value);
          }}
        />{" "}
        Mostrar opciones avanzadas
      </div>
      {showAdvancedOptions && (
        <List>
          {isPricesLoading ? (
            <div>loading...</div>
          ) : (
            groupedPrices.map((item, key) =>
              isEditingPriceRange[key] ? (
                <List.Item className="">
                  <div className="flex justify-between w-full">
                    <div>estamos editando</div>
                    {item.single_value ? (
                      <div>{item.start}</div>
                    ) : (
                      <div>
                        {item.start} a {item.end}
                      </div>
                    )}
                    <div>
                      <Input
                        onChange={(event) => {
                          console.log(event.target.value);
                          setNewUnitPrice(event.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <Button
                    className="mr-2"
                    type="link"
                    onClick={async () => {
                      message.loading("Procesando solicitud");
                      setIsEditingPriceRange((prevState) => {
                        let newIsEditingPriceRange = [...prevState];
                        newIsEditingPriceRange[key] = false;
                        return newIsEditingPriceRange;
                      });
                      const quantitiesToEdit = groupedPrices[
                        key
                      ].rangePrices.map((item) => item.quantity);
                      const idsToChange = prices.filter((item) =>
                        quantitiesToEdit.includes(item.quantity)
                      );
                      const val = await removePricesProducts(
                        idsToChange.map((item) => item.id)
                      );
                      console.log("prices removed", val);

                      for (let i in groupedPrices[key].rangePrices) {
                        console.log("i", i);
                        let newTotalPriceByQuantity =
                          groupedPrices[key].rangePrices[i].quantity < 10
                            ? newUnitPrice *
                                groupedPrices[key].rangePrices[i].quantity -
                              10
                            : newUnitPrice *
                              groupedPrices[key].rangePrices[i].quantity;
                        console.log("new info to add", {
                          productID,
                          quantity: groupedPrices[key].rangePrices[i].quantity,
                          newTotalPriceByQuantity,
                        });
                        const val = await addPricesProducts(
                          productID,
                          groupedPrices[key].rangePrices[i].quantity,
                          newTotalPriceByQuantity
                        );
                        console.log(val);
                      }
                      message.success("Cambio de precio completado!");
                    }}
                  >
                    <SaveOutlined />
                  </Button>
                </List.Item>
              ) : (
                <List.Item className="">
                  <Popover
                    placement="bottomLeft"
                    title={
                      <div>
                        {item.single_value ? (
                          <div>Cantidad {item.start}</div>
                        ) : (
                          <div>
                            Cantidades: {item.start} a {item.end}
                          </div>
                        )}
                      </div>
                    }
                    content={item.rangePrices.map((single_price) => (
                      <div>
                        Elemento: {single_price.quantity} x{" "}
                        {single_price.totalPrice}
                      </div>
                    ))}
                  >
                    <div className="flex justify-between w-full">
                      <div>
                        {item.single_value ? (
                          <div>{item.start}</div>
                        ) : (
                          <div>
                            {item.start} a {item.end}
                          </div>
                        )}
                      </div>
                      <div>{item.unitPrice}</div>
                    </div>
                  </Popover>

                  <Button
                    className="mr-2"
                    type="link"
                    onClick={() => {
                      setIsEditingPriceRange((prevState) => {
                        const newIsEditingPriceRange = [...prevState];
                        newIsEditingPriceRange[key] = true;
                        return newIsEditingPriceRange;
                      });
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </List.Item>
              )
            )
          )}
        </List>
      )}
    </>
  );
};

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputType === "number" ? <InputNumber /> : <Input />}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default PricesProducts;
